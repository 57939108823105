/**
 * Main index page (home page)
 *
 * Loads all posts from Ghost and uses pagination to navigate through them.
 * The number of posts that should appear per page can be setup
 * in /utils/siteConfig.js under `postsPerPage`.
 *
 */
import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";

import { Layout, Pagination, PostCard } from "../components/common";
import { MetaData } from "../components/common/meta";
import CategoryCard from "../components/CategoryCard";

import * as style from "../styles/one-pager.module.scss";
import { createCaseStudyTagIfNotExist } from "../utils/createCaseStudyTagIfNotExist";
/*eslint-disable max-lines*/
const Index = ({ data, location, pageContext }) => {
    const posts = data.allGhostPost.edges;
    const tags = data.allSiteTag.edges;
    const caseStudyPosts = data.allMarkdownRemark.edges;
    createCaseStudyTagIfNotExist(tags);

    const { ghostPage } = data;

    return (
        <>
            <MetaData location={location} data={{ ghostPage }} />
            <Layout
                headerImage={
                    data.headerImage.childImageSharp.gatsbyImageData.images
                        .fallback.src
                }
                headerText="Insights"
            >
                <div className={`${style.bgColorOffwhite}`}>
                    <div className="clarsynt-container py-5">
                        {/* <div className={`${style.insightsSearch}`}>
                            <input type="text" className="form-control" placeholder="Search our Insights page"
                                id="searchInput" />
                            <img src={searchIcon} alt="Search icon" />
                        </div> */}
                        <section className="post-feed flex-wrap">
                            {posts.map(({ node }) => (
                                // The tag below includes the markup for each post - components/common/PostCard.js
                                <PostCard key={node.id} post={node} />
                            ))}
                            {caseStudyPosts.map(({ node }) => (
                                <PostCard
                                    key={node.id}
                                    post={node.frontmatter}
                                    featureImage={
                                        node.frontmatter.feature_image.publicURL
                                    }
                                />
                            ))}
                        </section>
                        <div className={`${style.allCategories}`}>
                            <h2>All Categories</h2>
                            <div
                                className={`d-flex flex-wrap justify-content-center`}
                            >
                                {tags.map(
                                    ({ node }, i) =>
                                        !node.name.startsWith(`#`) && (
                                            <CategoryCard
                                                key={`category-${i}`}
                                                title={node.name}
                                                icon={node.og_image}
                                                slug={node.slug}
                                            />
                                        )
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                {/* recent articles section */}
                {/* <div className="clarsynt-container py-5">
                    <h2 className={`text-center`}>Recent Articles</h2>
                    <div className={`mt-5`}>
                        {posts.slice(0, visiblePosts).map(({ node }) => (
                            <PostListItem key={node.id} post={node} />
                        ))}
                        <div className={`d-flex justify-content-center`}>
                            <Button variant={`primary`}
                                size={`lg`}
                                className={`px-4 mt-4`}
                                onClick={loadMorePosts}>
                                Load More
                            </Button>
                        </div>
                    </div>
                </div> */}
                <Pagination pageContext={pageContext} />
            </Layout>
        </>
    );
};

Index.propTypes = {
    data: PropTypes.shape({
        allGhostPost: PropTypes.object.isRequired,
        allSiteTag: PropTypes.shape({
            edges: PropTypes.array,
        }).isRequired,
        allMarkdownRemark: PropTypes.object.isRequired,
        ghostPage: PropTypes.object,
        headerImage: PropTypes.object,
    }).isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
    pageContext: PropTypes.object,
};

export default Index;

// This page query loads all posts sorted descending by published date
// The `limit` and `skip` values are used for pagination
export const pageQuery = graphql`
    query FeaturedPostQuery {
        allGhostPost(
            sort: { fields: published_at, order: DESC }
            filter: { featured: { eq: true }, slug: { ne: "data-schema" } }
        ) {
            edges {
                node {
                    ...GhostPostFields
                }
            }
        }
        allSiteTag {
            edges {
                node {
                    slug
                    feature_image
                    name
                    og_image
                    url
                    postCount
                }
            }
        }
        headerImage: file(
            relativePath: { eq: "insight/bg-insights-header.jpg" }
        ) {
            childImageSharp {
                gatsbyImageData(layout: FIXED)
            }
        }
        ghostPage(slug: { eq: "insights" }) {
            meta_title
            meta_description
        }
        allMarkdownRemark(
            filter: { frontmatter: { featured_post: { eq: true } } }
        ) {
            edges {
                node {
                    id
                    frontmatter {
                        slug
                        title
                        feature_image {
                            publicURL
                        }
                        published_at_pretty: published_at(
                            formatString: "DD MMMM, YYYY"
                        )
                        excerpt
                    }
                }
            }
        }
    }
`;
