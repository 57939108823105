import React from "react";
import PropTypes from "prop-types";

import * as style from "../styles/category-card.module.scss";
import { Link } from "gatsby";

const CategoryCard = (props) => (
    <Link to={`/insights/${props.slug}`}>
        <div className={`p-2`}>
            <div className={`${style.clarsyntCategoryCard}`}>
                <img src={props.icon} />
                <div>
                    <div></div>
                    <h4>{props.title}</h4>
                </div>
            </div>
        </div>
    </Link>
);

CategoryCard.propTypes = {
    title: PropTypes.string.isRequired,
    icon: PropTypes.string,
    slug: PropTypes.string,
};
export default CategoryCard;
