export function createCaseStudyTagIfNotExist(tags) {
    const results = tags.filter((edge) => edge.node.slug === `case-studies`);
    if (results.length === 0) {
        tags.push({
            node: {
                name: `Case Studies`,
                slug: `case-studies`,
                og_image: `/images/tags/icon-insights-case-studies@2x.png`,
            },
        });
    }
}
